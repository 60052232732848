import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  GeneralPaymentDetails,
  InitiateOtherFeePayment,
} from "../../api/student";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import usePayments from "../../hooks/usePayments";
import api from "../../api/api";
import OtherFeesComponent from "../../components/OtherFeesComponent";

const OtherFees = () => {
  const user = getCurrentUser();
  const [fee, setFee] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentLevel, setPaymentLevel] = useState("");
  const [paymentSession, setPaymentSession] = useState(
    process.env.REACT_APP_CURRENT_SESSION
  );
  const [btnText, setBtnText] = useState("Proceed to payment");
  const { payment } = usePayments();

  const getPaymentDetails = async () => {
    if (!paymentType || !paymentLevel || !paymentSession) return;
    setLoading(true);
    try {
      const { data } = await api.post("/student/generate/other-fee", {
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      if (paymentType === "") return;
      if (data.status === 400) {
        toast.info(data.message);
        setLoading(false);
        setPaymentType("");
        setPaymentLevel("");
        setPaymentSession("");
      }
      console.log(data, "djfidjf");
      setFee(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const payFees = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateOtherFeePayment({
        student_id: user,
        payment_option: paymentType,
        payment_level: paymentLevel,
        payment_session: paymentSession,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  useEffect(() => {
    getPaymentDetails();
  }, [paymentType, paymentLevel, paymentSession]);
  return (
    <>
      <div class="page-wrapper">
        {payment && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Other Payments</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Other Payments</h4>
                  </div>
                  {payment?.school_fees_payment?.payment_status !==
                    "success" && (
                    <OtherFeesComponent
                      loading={loading}
                      btnText={btnText}
                      schoolFee={fee}
                      handlePayment={payFees}
                      paymentType={paymentType}
                      setPaymentType={setPaymentType}
                      paymentLevel={paymentLevel}
                      setPaymentLevel={setPaymentLevel}
                      paymentSession={paymentSession}
                      setPaymentSession={setPaymentSession}
                    />
                  )}
                  {/* {(payment?.school_fees_payment?.payment_status === 'success') &&
                    <div class="alert dspg-success" role="alert">
                    payments completed.
                  </div>
                    } */}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default OtherFees;
