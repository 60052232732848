import './App.css';
import {Routes, Route} from "react-router-dom"
import PortalWrapper from './pages/PortalWrapper';
import PortalLogin from './pages/PortalLogin';
import Dashboard from './pages/setup/Dashboard';
import SchoolFees from './pages/setup/SchoolFees';
import PaymentVerification from './pages/verification';
import SuccessPayment from './components/SuccessPayment';
import CourseRegistration from './pages/setup/CourseRegistration';
import Complaint from './pages/setup/Complaint';
import Accomodation from './pages/setup/Accomodation';
import { ToastContainer } from 'react-toastify';
import Library from './pages/setup/Library';
import SchoolFeeInvoice from './pages/SchoolFeeInvoice';
import HostelInvoice from './pages/HostelInvoice';
import UpdateBioData from './pages/setup/UpdateBioData';
import AllPayments from './pages/setup/invoice/AllPayments';
import ResetPassword from './pages/ResetPassword';
import NewPassword from './pages/NewPassword';
import CourseRegPrintout from './pages/setup/Print/CourseRegPrintout';
import AllHostelPayments from './pages/setup/invoice/AllHostelPayment';
import GeneralReceipt from './pages/GeneralReceipt';
import OtherFees from './pages/setup/OtherFees';
import RemedialRegistration from './pages/setup/remedial/RemedialRegistration';
import OtherPayments from './pages/setup/invoice/OtherPayments';
import RemedialPayment from './pages/setup/invoice/RemedialPayment';
import RemedialInvoice from './pages/RemedialInvoice';
import PreviousSchoolFees from './pages/setup/PreviousSchoolFees';

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <Routes>

        <Route exact path='/invoice/school-fee' element={<SchoolFeeInvoice/>}/>
        <Route exact path='/invoice/hostel' element={<HostelInvoice/>}/>
        {/* <Route exact path='/invoice/remedial' element={<RemedialInvoice/>}/> */}
        <Route exact path='/receipt' element={<GeneralReceipt/>}/>
        {/*Verification*/}
        <Route exact path='/paystack/verify' element={<PaymentVerification/>}/>
        {/*success*/}
        <Route exact path='/successful' element={<SuccessPayment/>}/>

        {/*Reset password*/}
        <Route exact path="/reset-password" element={<ResetPassword/>}/>
        <Route exact path="/password/reset/:uidb64/:token" element={<NewPassword/>}/>


        <Route exact path='/login' element={<PortalLogin/>}/>
        <Route exact path='/' element={<PortalWrapper/>}>
          <Route exact path='' element={<Dashboard/>}/>
          <Route exact path='/payment' element={<SchoolFees/>}/>
          <Route exact path='/previous-payment' element={<PreviousSchoolFees/>}/>
          <Route exact path='/other-payments' element={<OtherFees/>}/>
          <Route exact path='/course-reg' element={<CourseRegistration/>}/>
          <Route exact path='complaint' element={<Complaint/>}/>
          <Route exact path='/accommodation' element={<Accomodation/>}/>
          <Route exact path='/accommodation/history' element={<AllHostelPayments/>}/>
          <Route exact path='library' element={<Library/>}/>
          <Route exact path='/biodata' element={<UpdateBioData/>}/>
          <Route exact path='/history/payments' element = {<AllPayments/>}/>
          <Route exact path='/history/other-payments' element = {<OtherPayments/>}/>
          <Route exact path='/course-reg/print' element={<CourseRegPrintout/>}/>
          {/* <Route exact path='/remedials' element={<RemedialRegistration/>}/> */}
          {/* <Route exact path='/remedials/history' element={<RemedialPayment/>}/> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
