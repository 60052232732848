import React from "react";

const StudentInfo = ({data, schoolFee}) => {
  const handleDetailsCheck = (value) => {
    if (!value) return "---"
      return value
  }
  return (
    <>
      <p className="breadcrumb-item active">Student Information</p>

      <div class="form-group row">
        <div class="col-md-4">
          <label>Application Number</label>
          <h6>{handleDetailsCheck(data?.application_no)}</h6>
        </div>
        <div class="col-md-4">
          <label>Matriculation Number</label>
          <h6>{handleDetailsCheck(data?.matric_no)}</h6>
        </div>
        <div class="col-md-4">
          <label>Academic Session</label>
          <h6>{handleDetailsCheck(data?.entry_session)}</h6>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <label>Faculty</label>
          <h6>{handleDetailsCheck(data?.faculty)}</h6>
        </div>
        <div class="col-md-4">
          <label>Department</label>
          <h6>{handleDetailsCheck(data?.department)}</h6>
        </div>
        <div class="col-md-4">
          <label>Fee payment</label>
          <h6>{schoolFee === "full" ? "PAID (full)" : schoolFee === "part" ? "PAID (part)": "NOT PAID"}</h6>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <label>Stat of Origin</label>
          <h6>{handleDetailsCheck(data?.state_origin)}</h6>
        </div>
        <div class="col-md-4">
          <label>Level</label>
          <h6>{handleDetailsCheck(data?.level)}</h6>
        </div>
        <div class="col-md-4">
          <label>Study Mode</label>
          <h6>{handleDetailsCheck(data?.study_mode)}</h6>
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
