import useDetails from "../hooks/useDetails";
import {
  OtherPaymentOptions,
  numberWithCommas,
} from "../utils/appParams";
import StudentInfo from "./StudentInfo";
import "./Style.css";

const OtherFeesComponent = ({
  loading,
  btnText,
  handlePayment,
  schoolFee,
  paymentType,
  setPaymentType,
  paymentLevel,
  setPaymentLevel,
  paymentSession,
  setPaymentSession
}) => {
  const { data } = useDetails();
  //   const {data:datas} = useProspective()

  const handleSelection = (payment) => {
    setPaymentType(payment.target.value);
    console.log(payment.target.value);
  };
  return (
    <>
      {data && (
        <div>
          <div class=" col-md-12 card p-5" style={{ padding: "10px" }}>
            <StudentInfo data={data} />
            <div className="col-lg-12 bg-light p-5">
              <h4>Payment for:</h4>
              <select
                className="form-control col-lg-6"
                value={paymentType}
                onChange={handleSelection}
              >
                <option value="">--Select Payment--</option>
                {OtherPaymentOptions?.map((option) => (
                  <option value={(option.value)?.toUpperCase()}>{option.name}</option>
                ))}
              </select>
              <br/>
              <h4>Select the level</h4>
              <select
                className="form-control col-lg-6"
                value={paymentLevel}
                onChange={(e) => setPaymentLevel(e.target.value)}
              >
                <option value=''>--Select--</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
              </select>
              <br/>
              <h4>Select the session</h4>
              <select
                className="form-control col-lg-6"
                value={paymentSession}
                onChange={(e) => setPaymentSession(e.target.value)}
              >
                <option value=''>--Select--</option>
                <option value="2018/2019">2018/2019</option>
                <option value="2019/2020">2019/2020</option>
                <option value="2020/2021">2020/2021</option>
                <option value="2021/2022">2021/2022</option>
                <option value="2022/2023">2022/2023</option>
                <option value="2023/2024">2023/2024</option>
              </select>
            </div>
            {(paymentType && paymentLevel && paymentSession) && (
              <>
                <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
                    <h4>{schoolFee && schoolFee?.payment}</h4>
                  <div class="d-flex flex-row align-items-center">
                    <sup class="dollar font-weight-bold">₦</sup>
                    <span class="amount ml-1 mr-1">
                      {schoolFee && (schoolFee?.amount)}
                    </span>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    class="btn btn-primary btn-raised waves-effect"
                    onClick={handlePayment}
                    disabled={loading}
                  >
                    {btnText} <i class="fa fa-long-arrow-right"></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OtherFeesComponent;
