export let URL = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_LOCAL_URL : (process.env.REACT_APP_ENV === 'staging' ? process.env.REACT_APP_STAGING_URL : (process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : ''));

// export let auth_token = localStorage.getItem('student-token');

export const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  export const auth_token = () => {
    return localStorage.getItem('student-token') 
  }

  export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const paymentOptions = [
    {
      name: "School Fees - Full Payment",
      value:'full'
    },
    {
      name: "School Fees - First Installment",
      value:'part'
    },
    {
      name: "School Fees - Second Installment",
      value:'second_installment'
    },
    {
      name: "Additional Payment",
      value:'additional'
    },
  ]

  export const OtherPaymentOptions = [
    {
      name: "ACCT",
      value: "ACCT",
    },
    {
      name: "BFN",
      value: "BFN",
    },
    {
      name: "BAM",
      value: "BAM",
    },
    {
      name: "CHANGE OF COURSE",
      value: "COURSE CHANGE",
    },
    {
      name: "EED 2",
      value: "EED 2",
    },
    {
      name: "EED 1",
      value: "EED 1",
    },
    {
      name: "EED 1 PT",
      value: "EED 1 PT",
    },
    {
      name: "EED 2 PT",
      value: "EED 2 PT",
    },
    {
      name: "EED EXTRA PT",
      value: "EED EXTRA PT",
    },
    {
      name: "GNS",
      value: "GNS",
    },
    {
      name: "HTM PACK",
      value: "HTM PACK",
    },
    {
      name: "HRM PACK",
      value: "HRM PACK",
    },
    {
      name: "LIS PACK",
      value: "LIS PACK",
    },
    {
      name: "MKT",
      value: "MKT",
    },
    {
      name: "MCM",
      value: "MCM",
    },
    {
      name: "matric gown",
      value: "matric gown",
    },
    {
      name: "ND RESULT VERIFICATION",
      value: "ND RESULT VERIFICATION",
    },
    {
      name: "NECO E-verify",
      value: "NECO E-VERIFY",
    },
    {
      name: "NIPOGA",
      value: "NIPOGA",
    },
    {
      name: "OTM",
      value: "OTM",
    },
    {
      name: "POM",
      value: "POM",
    },
    {
      name: "PAD",
      value: "PAD",
    },
    {
      name: "SUG",
      value: "SUG",
    },
    {
      name: "STA PACK",
      value: "STA PACK",
    },
    {
      name: "SIct pack I",
      value: "SIct pack I",
    },
    {
      name: "SICT II",
      value: "SICT II",
    },
    {
      name: "SICT I",
      value: "SICT I",
    },
    {
      name: "Sch of Eng",
      value: "Sch of Eng",
    },
    {
      name: "SBS PACK I",
      value: "SBS PACK I",
    },
    {
      name: "SBS PACK II",
      value: "SBS PACK II",
    },
    {
      name: "SBS PACK I",
      value: "SBS PACK I",
    },
    {
      name: "SAS PACK II",
      value: "SAS PACK II",
    },
    {
      name: "SAS PACK I",
      value: "SAS PACK I",
    },
    {
      name: "SIWES",
      value: "SIWES",
    },
    {
      name: "SPORTS CLEARANCE",
      value: "SPORTS CLEARANCE",
    },
  ]