import React, { useState } from 'react'
import Footer from '../../components/Footer'
import useDetails from '../../hooks/useDetails'
import api from '../../api/api'

const Complaint = () => {
    const [loading, setLoading] = useState(false)
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [successShow, setSuccessShow] = useState(false)
    const {data:details} = useDetails()

    const sendComplaint = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const {data} = await api.post('/complaint/', {
                subject,
                message,
                name: details?.surname +" "+ details?.othername,
                email:details?.email,
            })
            console.log(data)
            setLoading(false)
            setSuccessShow(true)
            setMessage("")
            setSubject("")
        } catch (error) {
            setLoading(false)
            
        }
    }
  return (
    <div class="page-wrapper">
      {details && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Complaint Management</li>
                  </ol>
                </div>
                <h4 class="page-title">Complaint Management</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="">
                            <form
                              class="form-horizontal form-material mb-0"
                              onSubmit={sendComplaint}
                            > 
                                {successShow &&
                                <div class="alert icon-custom-alert alert-outline-success alert-success-shadow fade show" role="alert">
                                    <i class="mdi mdi-check-all alert-icon"></i>
                                    <div class="alert-text">
                                        <strong>Successful!</strong> Complaint has been sent.
                                    </div>
                                    <div class="alert-close">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="mdi mdi-close text-success"></i></span>
                                        </button>
                                    </div>                                            
                                </div>
                                }
                              <div class="form-group row">
                                <div class="col-md-12">
                                  <label>Subject</label>
                                  <input
                                    type="text"
                                    placeholder="Enter subject of complaint"
                                    class="form-control"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="form-group">
                                <label>Message</label>
                                <textarea
                                  rows="5"
                                  placeholder="Provide the details of your complaint"
                                  class="form-control"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                  required
                                ></textarea>
                                <button
                                  class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                                  type="submit"
                                  disabled={loading ? true : false}
                                >
                                  Send Complaint
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal modal-rightbar fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="MetricaRightbar"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title mt-0" id="MetricaRightbar">
                  Appearance
                </h5>
                <button
                  type="button"
                  class="btn btn-sm btn-soft-primary btn-circle btn-square"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="modal-body">
                <ul
                  class="nav nav-pills nav-justified mt-2 mb-4"
                  role="tablist"
                >
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#ActivityTab"
                      role="tab"
                    >
                      Activity
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#TasksTab"
                      role="tab"
                    >
                      Tasks
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#SettingsTab"
                      role="tab"
                    >
                      Settings
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div
                    class="tab-pane active "
                    id="ActivityTab"
                    role="tabpanel"
                  >
                    <div class="bg-light mx-n3">
                      <img
                        src="../assets/images/small/img-1.gif"
                        alt=""
                        class="d-block mx-auto my-4"
                        height="180"
                      />
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="activity">
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-timer-off bg-soft-pink"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                50 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task Overdue
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                            <span class="badge badge-soft-secondary">
                              Design
                            </span>
                            <span class="badge badge-soft-secondary">HTML</span>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-alert-decagram bg-soft-purple"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 hours ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Task
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-warning"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                yesterday
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Comment
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-secondary"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                01 feb 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Lead Meting
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                26 jan 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="TasksTab" role="tabpanel">
                    <div class="m-0">
                      <div id="rightbar_chart" class="apex-charts"></div>
                    </div>
                    <div class="text-center mt-n2 mb-2">
                      <button type="button" class="btn btn-soft-primary">
                        Create Project
                      </button>
                      <button type="button" class="btn btn-soft-primary">
                        Create Task
                      </button>
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="p-2">
                        <div class="media mb-3">
                          <img
                            src="../assets/images/widgets/project3.jpg"
                            alt=""
                            class="thumb-lg rounded-circle"
                          />
                          <div class="media-body align-self-center text-truncate ml-3">
                            <p class="text-success font-weight-semibold mb-0 font-14">
                              Project
                            </p>
                            <h4 class="mt-0 mb-0 font-weight-semibold text-dark font-18">
                              Payment App
                            </h4>
                          </div>
                        </div>
                        <span>
                          <b>Deadline:</b> 02 June 2020
                        </span>
                        <a href="javascript: void(0);" class="d-block mt-3">
                          <p class="text-muted mb-0">
                            Complete Tasks<span class="float-right">75%</span>
                          </p>
                          <div class="progress mt-2" style={{ height: "4px" }}>
                            <div
                              class="progress-bar bg-secondary"
                              role="progressbar"
                              style={{ width: "75%" }}
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </a>
                      </div>
                      <hr class="hr-dashed" />
                    </div>
                  </div>
                  <div class="tab-pane" id="SettingsTab" role="tabpanel">
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">Account Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch1"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch1"
                        >
                          Auto updates
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch2"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch2"
                        >
                          Location Permission
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch3"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch3"
                        >
                          Show offline Contacts
                        </label>
                      </div>
                    </div>
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">General Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch4"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch4"
                        >
                          Show me Online
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch5"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch5"
                        >
                          Status visible to all
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch6"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch6"
                        >
                          Notifications Popup
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      }
    </div>
  )
}

export default Complaint