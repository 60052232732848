import useDetails from "../hooks/useDetails";
import { numberWithCommas } from "../utils/appParams";
import StudentInfo from "./StudentInfo";
import "./Style.css";
import { paymentOptions } from "../utils/appParams";
import Loader from "./Loader";

const PreviousFeeComponent = ({
  loading,
  btnText,
  handlePayment,
  schoolFee,
  paymentType,
  setPaymentType,
  paymentLevel,
  setPaymentLevel,
  paymentSession,
  setPaymentSession,
  availableSessions,
}) => {
  const { data } = useDetails();
  //   const {data:datas} = useProspective()

  const handleSelection = (payment) => {
    setPaymentType(payment.target.value);
    console.log(payment.target.value);
  };
  return (
    <>
      {data && !loading && (
        <div>
          <div class="card p-5" style={{ padding: "10px" }}>
            {/* <StudentInfo data={data} /> */}
            <div className="col-lg-12 bg-light p-5">
              <h4>Select the payment you want to make</h4>
              <select
                className="form-control col-lg-6"
                value={paymentType}
                onChange={handleSelection}
              >
                <option value="">--Select Payment--</option>
                {paymentOptions?.map((option) => (
                  <option value={option.value}>{option.name}</option>
                ))}
              </select>
              <br />
              <h4>Select the level</h4>
              <select
                className="form-control col-lg-6"
                value={paymentLevel}
                onChange={(e) => setPaymentLevel(e.target.value)}
              >
                <option value="">--Select--</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
              </select>
              <br />
              <h4>Select the session</h4>
              <select
                className="form-control col-lg-6"
                value={paymentSession}
                onChange={(e) => setPaymentSession(e.target.value)}
              >
                <option value="">--Select--</option>
                {availableSessions?.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            {paymentType && paymentLevel && paymentSession && (
              <>
                <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
                  <div class="d-flex flex-row align-items-center">
                    <sup class="dollar font-weight-bold">₦</sup>
                    <span class="amount ml-1 mr-1">
                      {schoolFee && numberWithCommas(schoolFee?.amount)}
                    </span>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    class="btn btn-primary btn-raised waves-effect"
                    onClick={handlePayment}
                    disabled={loading}
                  >
                    {btnText} <i class="fa fa-long-arrow-right"></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {loading && <Loader />}
    </>
  );
};

export default PreviousFeeComponent;
